<template>
  <div id="page-user-list">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <vx-card ref="filterCard" :title="$t('orderPage.filter')" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters" @remove="resetColFilters">
        <div class="vx-row">
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{$t('role')}}</label>
            <v-select :options="roleOptions" :clearable="false" :label="`name_${$i18n.locale}`" v-model="currentRole" class="mb-4 md:mb-0" :reduce="opt => opt.value"  />
          </div>
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
            <label class="text-sm opacity-75">{{$t('status')}}</label>
            <v-select :options="statusOptions" :clearable="false" :label="`name_${$i18n.locale}`" v-model="current" class="mb-4 md:mb-0" :reduce="opt => opt.value"  />
          </div>
        </div>
      </vx-card>
      <div class="vx-card p-6">
        <vs-table hoverFlat ref="table" :data="usersData" search stripe pagination :max-items="itemsPerPage">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-no-wrap">
              <div class="flex flex-wrap-reverse items-center">
                <router-link v-if="$acl.check('superadmin')" :to="{name: 'operatorCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                  <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
                </router-link>
              </div>
            </div>
            <div>
              <!-- <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mx-2">Role: </span>
                  <span class="capitalize mr-2">{{currentRole}}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="role in roleOptions" :key="role.value" @click="currentRole = role.value">
                    <span>{{ role[`name_${$i18n.locale}`] }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mx-2">Status: </span>
                  <span class="capitalize mr-2">{{current}}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="stat in statusOptions" :key="stat.value" @click="current = stat.value">
                    <span>{{ stat[`name_${$i18n.locale}`] }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown> -->
              <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ usersData.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : usersData.length }} of {{ queriedItems }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="opt in perPageOps" :key="opt" @click="itemsPerPage=opt">
                    <span>{{ opt }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
          <template slot="thead">
            <vs-th class="pr-0">#ID</vs-th>
            <!-- <vs-th class="pl-0">Kod</vs-th> -->
            <vs-th class="pl-0" sort-key="name">{{ $t('fields.name') }}</vs-th>
            <vs-th class="hidden lg:table-cell" sort-key="phone">{{ $t('fields.username') }}</vs-th>
            <vs-th class="hidden lg:table-cell" sort-key="phone">{{ $t('props.phone') }}</vs-th>
            <vs-th class="hidden lg:table-cell" sort-key="email">{{ $t('fields.email') }}</vs-th>
            <vs-th sort-key="role">{{ $t('role') }}</vs-th>
            <vs-th sort-key="isBlocked">{{ $t('fields.active') }}</vs-th>
            <vs-th><span class="">{{ $t('fields.actions') }}</span></vs-th>
          </template>


          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
              <vs-td>
                {{ data[indextr].id }}
              </vs-td>            
              <vs-td class="pl-0" :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].username" class="hidden lg:table-cell">
                {{ data[indextr].username }}
              </vs-td>
              <vs-td :data="data[indextr].phone" class="hidden lg:table-cell">
                {{ data[indextr].phone }}
              </vs-td>
              <vs-td :data="data[indextr].email" class="hidden lg:table-cell">
                {{ data[indextr].email }}
              </vs-td>
              <vs-td :data="data[indextr].role">
                <vs-chip :color="getRoleColor(data[indextr].role)">
                  {{ data[indextr].role | capitalize }}
                </vs-chip>
              </vs-td>
              <vs-td :data="data[indextr].isBlocked">
                <vs-chip :color="!tr.isBlocked ? 'primary' : 'danger'">
                  <feather-icon v-if="!tr.isBlocked" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td class="whitespace-no-wrap text-left">
                <feather-icon icon="EyeIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="" :title="$t('details')" @click.stop="showData(tr.uuid)" />
                <template v-if="$acl.check('superadmin')" >
                  <feather-icon icon="EditIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" :title="$t('edit')" @click.stop="editData(tr.uuid)" />
                  <template v-if="tr.uuid !== activeUser.uuid">
                    <feather-icon v-if="!tr.isBlocked" icon="LockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-danger' ]"  class=" ml-2" :title="$t('block')" @click.stop="confirmStatusChange(tr.uuid, tr.name, tr.isBlocked)" />
                    <feather-icon v-else icon="UnlockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" :title="$t('unblock')" @click.stop="confirmStatusChange(tr.uuid, tr.name, tr.isBlocked)" />
                  </template>
                </template>
                <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  data () {
    return {
      current: 'all',
      currentRole: 'all',
      itemsPerPage: 15,
      perPageOps: [4, 10, 15, 20],
      isMounted: false,
      editing: {},
      users: [],
      roleOptions: [
        { name_en: 'All', name_tm: 'Ählisi', name_ru: 'Все', value: 'all' },
        { name_en: 'Admin', name_tm: 'Admin', name_ru: 'Админ', value: 'admin'},
        { name_en: 'Superadmin', name_tm: 'Superadmin', name_ru: 'Суперадмин', value: 'superadmin'},
        { name_en: 'Operator', name_tm: 'Operator', name_ru: 'Оператор', value: 'operator'},
        { name_en: 'Stock operator', name_tm: 'Ammar operator', name_ru: 'Склад оператор', value: 'stock_operator'}
      ],
      statusOptions: [
        { name_en: 'All', name_tm: 'Ählisi', name_ru: 'Все', value: 'all' },
        { name_en: 'Active', name_tm: 'Aktiw', name_ru: 'Активные', value: 'active' },
        { name_en: 'Blocked', name_tm: 'Bloklanan', name_ru: 'Блокирован', value: 'blocked' }
      ],
      searchQuery: '',
      isLoading: true
    }
  },
  computed: {
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.usersData.length },
    usersData () {
      switch (true) {
      case (this.current === 'all' && this.currentRole === 'all'): return this.users
      case (this.current === 'all' && this.currentRole !== 'all'): return this.users.filter(usr => usr.role === this.currentRole)
      case (this.current === 'active' && this.currentRole === 'all'): return this.users.filter(usr => !usr.isBlocked)
      case (this.current === 'active' && this.currentRole !== 'all'): return this.users.filter(usr => !usr.isBlocked && usr.role === this.currentRole)
      case (this.current === 'blocked' && this.currentRole === 'all'): return this.users.filter(usr => usr.isBlocked)
      case (this.current === 'blocked' && this.currentRole !== 'all'): return this.users.filter(usr => usr.isBlocked && usr.role === this.currentRole)
      }
    },
    activeUser () { return this.$store.state.AppActiveUser }
  },
  methods: {
    async fetchData () {
      this.$http.get('/admins')
        .then((response) => {
          this.users = response.data.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$vs.notify({
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    resetColFilters () {
      this.current = 'all'
      this.currentRole = 'all'
      this.$refs.filterCard.removeRefreshAnimation()
    },
    editData (id) { this.$router.push(`/operators/${id}/edit`) },
    showData (id) { this.$router.push(`/operators/${id}`) },
    confirmStatusChange (id, name, status) {
      this.editing = { id, status }
      this.$vs.dialog({
        type: 'confirm',
        color: status ? 'primary' : 'danger',
        title: this.$t('withdraw'),
        text: `${status ? this.$t('confirmUnblock') : this.$t('confirmBlock')} "${name}"`,
        accept: this.changeStatus,
        acceptText: this.$t('submit'),
        cancelText: this.$t('cancel')
      })
    },
    async changeStatus () {
      await this.$http.patch(`/admins/edit/${this.editing.id}`, { isBlocked: !this.editing.status }).then(() => {
        this.fetchData()
        this.editing = {}
        this.$vs.notify({
          title: this.$t('notify.success'),
          text: `${this.$t('status')} ${this.$t('notify.sUpdated')}`,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      }).catch(err => {
        this.editing = {}
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    getRoleColor (role) {
      if (role === 'superadmin')      return 'success'
      if (role === 'admin')           return 'primary'
      if (role === 'operator')        return 'warning'
      if (role === 'stock_operator')  return 'danger'
      return 'primary'
    }
  },
  async created () {
    await this.fetchData()
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .vs-con-table {
    .img {
      background-color: #cdcdcd;
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--tr {
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>
